import {
  BroadFulfilmentInput,
  BroadFulfilmentMethodInputType,
} from '@src/graphql-types'
import { ExtendedNonBasketOutletFulfilment } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/extendData/types'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'

export const getBroadFulfilmentInputFromOutletFulfilment = (
  currentFulfilment: ExtendedNonBasketOutletFulfilment['currentFulfilment'],
  isPendingTableNumbersEnabled: boolean
): BroadFulfilmentInput | null => {
  if (currentFulfilment.type === CurrentFulfilmentType.COLLECTION) {
    return {
      broadFulfilmentMethod: BroadFulfilmentMethodInputType.COLLECTION,
    }
  }
  if (currentFulfilment.type === CurrentFulfilmentType.TABLE) {
    return {
      broadFulfilmentMethod: BroadFulfilmentMethodInputType.TABLE,
      tableId: currentFulfilment.tableId,
    }
  }
  if (currentFulfilment.type === CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS) {
    return {
      broadFulfilmentMethod: BroadFulfilmentMethodInputType.ADDRESS,
      addressId: currentFulfilment.addressId,
    }
  }
  if (currentFulfilment.type === CurrentFulfilmentType.DELIVERY_ZONE) {
    return {
      broadFulfilmentMethod: BroadFulfilmentMethodInputType.FIXED_ZONE_DELIVERY,
      deliveryZoneId: currentFulfilment.zoneId,
    }
  }
  if (currentFulfilment.type === CurrentFulfilmentType.DELIVERY_COORDINATES) {
    return {
      broadFulfilmentMethod: BroadFulfilmentMethodInputType.COORDINATES,
      latitude: currentFulfilment.location.coordinates.lat,
      longitude: currentFulfilment.location.coordinates.lng,
    }
  }
  if (currentFulfilment.type === CurrentFulfilmentType.DELIVERY_POSTCODE) {
    return {
      broadFulfilmentMethod: BroadFulfilmentMethodInputType.POSTCODE,
      ...currentFulfilment.postAndCountryCode,
    }
  }
  if (
    currentFulfilment.type === CurrentFulfilmentType.TABLE_UNSELECTED &&
    isPendingTableNumbersEnabled
  ) {
    return {
      broadFulfilmentMethod: BroadFulfilmentMethodInputType.TABLE,
      tableId: null,
    }
  }

  console.error('Unexpected currentFulfilment type', currentFulfilment)
  return null
}
