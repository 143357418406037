import React from 'react'

import { MobileOutletServicePopover } from '@src/components/SiteHeader/ServiceNavigation/Popovers/MobileOutletServicePopover'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useAllDiscountsQuery } from '@src/hooks/sharedQueries/useAllDiscounts/useAllDiscounts'
import { useBreakpoint } from '@src/hooks/useBreakpoint'
import { useServiceComponentModal } from '@src/hooks/useServiceComponentModal/useServiceComponentModal'

import { HeaderTab, TabString } from './HeaderTab'
import { OutletHeaderMobile } from './OutletHeaderMobile'
import { ServiceTabsContainer } from './OutletHeaderStyles'

export const FulfilmentMethodTabMap: Record<
  NarrowFulfilmentMethodInputType,
  TabString[]
> = {
  [NarrowFulfilmentMethodInputType.TABLE]: [
    TabString.SERVICE,
    TabString.LOCATION,
  ],
  [NarrowFulfilmentMethodInputType.COLLECTION]: [
    TabString.SERVICE,
    TabString.WHEN,
  ],
  [NarrowFulfilmentMethodInputType.DELIVERY]: [
    TabString.SERVICE,
    TabString.WHEN,
    TabString.WHERE,
  ],
}

export const OutletHeader: React.FC<{
  compactStyle: boolean
}> = ({ compactStyle }) => {
  const { openServiceComponentModal } = useServiceComponentModal()
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const { isTabletOrGreater } = useBreakpoint()
  const { data, previousData } = useAllDiscountsQuery({
    outletId: outletFulfilment.outlet.id,
    fulfilmentNarrowType: outletFulfilment.data.currentFulfilment.narrowType,
  })

  const selectedNarrowFulfilment =
    outletFulfilment.data.currentFulfilment.narrowType

  const hideTableTab =
    outletFulfilment.data.currentFulfilment.narrowType ===
      NarrowFulfilmentMethodInputType.TABLE &&
    outletFulfilment.outlet.isPendingTableNumbersEnabled

  const fulfilmentTabs = hideTableTab
    ? [TabString.SERVICE]
    : FulfilmentMethodTabMap[selectedNarrowFulfilment]
  const hasDiscounts = Boolean(
    (data || previousData)?.allDiscountsByOutletId?.length
  )

  const handleClick = () => {
    openServiceComponentModal()
  }

  return (
    <>
      <ServiceTabsContainer
        onClick={handleClick}
        compactStyle={compactStyle}
        hasOffers={
          hasDiscounts && outletFulfilment.outlet.restaurant.dealsText !== null
        }
      >
        {isTabletOrGreater ? (
          <>
            <HeaderTab
              fulfilmentTabs={fulfilmentTabs}
              dataTestId="service-tabs-container"
            />
          </>
        ) : (
          <OutletHeaderMobile
            selectedNarrowFulfilment={
              outletFulfilment.data.currentFulfilment.narrowType
            }
          />
        )}
      </ServiceTabsContainer>
      <MobileOutletServicePopover />
    </>
  )
}
