import { useQuery } from '@apollo/client'
import { sum } from 'lodash'
import { useMemo } from 'react'

import { BroadFulfilmentMethodInputType } from '@src/graphql-types'

import { BasketOutletMenuItems } from './queries/__generated__/BasketOutletMenuItems'
import { BasketOutletMenuItemsDocument } from './queries/__generated__/BasketOutletMenuItems.graphql-interface'
import {
  CheckoutBasketTotalsDocument,
  CheckoutBasketTotalsQuery,
} from './queries/__generated__/checkoutBasketTotals.graphql-interface'
import { getBroadFulfilmentInputFromOutletFulfilment } from './utils/getBroadFulfilmentInputFromOutletFulfilment'
import { mergeBasketItemsWithPrices } from './utils/mergeBasketItemsWithPrices'

import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '../outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { AsyncDataWrapper } from '../types'
import { useBasketItems } from '../useBasketItems/useBasketItems'
import { BasketItem } from '../useBasketItems/validation'
import { useDiscounts } from '../useDiscounts/useDiscounts'

type UseBasketTotalsResponseData = {
  checkoutBasketTotals: CheckoutBasketTotalsQuery['checkoutBasketTotals'] | null
  basketItemsWithPrices: BasketItemWithPrices[]
  basketItemsTotal: number
}

export type BasketOutletMenuItem =
  BasketOutletMenuItems['outletMenuItems'][number]
export type BasketItemWithPrices = {
  basketItem: BasketItem
  outletMenuItem: BasketOutletMenuItem
  selectedOptionItems: BasketOutletMenuItem['options'][number]['optionItems']
  isAddOnItem: boolean
  // price of the menu item with the selected options multiplied by the quantity
  totalPrice: number
}

// hook which uses the data from the basket and fulfilment models (ie persisted global state) to calculate:
// the basket total, the fulfilment total, the service charge, any discounts and the sum total
// returns null if the basket is empty
export const useBasketTotals = ({
  addressIdOverride,
}: {
  addressIdOverride?: string
} = {}): AsyncDataWrapper<UseBasketTotalsResponseData> => {
  const basketItems = useBasketItems()
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const { discounts } = useDiscounts()

  // get the prices for the non add on items
  const basketOutletMenuItemsQueryResponse = useQuery(
    BasketOutletMenuItemsDocument,
    {
      variables: {
        outletMenuItemIds: basketItems.uniqueOutletMenuItemIds,
      },
    }
  )
  const { data: basketOutletMenuItemData } = basketOutletMenuItemsQueryResponse

  // calculate the prices of all the items in the basket
  const basketItemsWithPricesById = useMemo(() => {
    if (!basketOutletMenuItemData) {
      return {}
    }
    return mergeBasketItemsWithPrices({
      basketItems: basketItems.items,
      basketOutletMenuItems: basketOutletMenuItemData.outletMenuItems,
    })
  }, [basketItems.items, basketOutletMenuItemData])

  const basketItemsWithPrices = Object.values(basketItemsWithPricesById)

  // calculate the non-add on items total
  const nonAddOnBasketItemsTotal = sum(
    basketItemsWithPrices
      .filter(item => !item.isAddOnItem)
      .map(item => item.totalPrice)
  )

  // calculate the add on items total
  const addOnBasketItemsTotal = sum(
    basketItemsWithPrices
      .filter(item => item.isAddOnItem)
      .map(item => item.totalPrice)
  )

  const broadFulfilmentInput = addressIdOverride
    ? {
        broadFulfilmentMethod: BroadFulfilmentMethodInputType.ADDRESS,
        addressId: addressIdOverride,
      }
    : getBroadFulfilmentInputFromOutletFulfilment(
        outletFulfilment.data.currentFulfilment,
        outletFulfilment.outlet.isPendingTableNumbersEnabled
      )

  // TODO: handle refetching basket on expiryAt
  const checkoutBasketTotalsQueryResponse = useQuery(
    CheckoutBasketTotalsDocument,
    broadFulfilmentInput && basketItemsWithPrices.length > 0
      ? {
          variables: {
            basketData: {
              outletId: outletFulfilment.outlet.id,
              nonAddOnBasketItemsTotal,
              addOnBasketItemsTotal,
              fulfilment: broadFulfilmentInput,
              voucherDiscountId: discounts.voucherDiscountId,
            },
          },
        }
      : { skip: true }
  )

  const previousTotals: UseBasketTotalsResponseData = {
    checkoutBasketTotals:
      checkoutBasketTotalsQueryResponse.previousData?.checkoutBasketTotals ||
      null,
    basketItemsWithPrices,
    basketItemsTotal: nonAddOnBasketItemsTotal + addOnBasketItemsTotal,
  }

  if (
    basketOutletMenuItemsQueryResponse.loading ||
    checkoutBasketTotalsQueryResponse.loading
  ) {
    return {
      data: previousTotals,
      loading: true,
      error: null,
    }
  }

  const newTotals: UseBasketTotalsResponseData = {
    checkoutBasketTotals:
      checkoutBasketTotalsQueryResponse.data?.checkoutBasketTotals || null,
    basketItemsWithPrices,
    basketItemsTotal: nonAddOnBasketItemsTotal + addOnBasketItemsTotal,
  }

  return {
    data: newTotals,
    loading: false,
    error: null,
  }
}
